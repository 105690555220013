import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import classNames from 'classnames'

import DefaultLayout from '../../layout/default'

import CtaForm from '../../pagesSections/index/CtaForm'
import { getData as getFaqData } from '../../pages-data/_V2/prices/faq'
import { externalLinks } from '../../pages-data/_V2/common/links'

import pageStyles from '../../styles/_V2/pagesStyles/styles.module.scss'
import styles from '../styles/_V2/prices.module.scss'
import Presentation from '../../pagesSections/index/ProductPresentation/assets/pres.png'
import PresentationBy from '../../pagesSections/index/ProductPresentation/assets/pres_by.png'
import PresentationKz from '../../pagesSections/index/ProductPresentation/assets/pres_kz.png'
import Tariffs from '../../components/_V2/prices/Tariffs'
import Features from '../../pagesSections/prices/Features'
import Faq from '../../components/_V2/Faq'
import DetailFeatures from '../../pagesSections/prices/DetailFeatures'
import Services from '../../pagesSections/prices/Services'
import EnterpriseCta from '../../pagesSections/prices/EnterpriseCta'
import OurCustomers from '../../pagesSections/index/OurCustomers'
import { useLocalizationContext } from '../../localization/useLocalizationContext'
import LoyaltyTariffs from "../../pagesSections/prices/LoyaltyTariffs"

const MODULES_COST_INITIAL = {
  cashier: {
    month: 0,
    month3: 0,
    month6: 0,
    year: 0,
    yearOld: 0,
  },
  smart: {
    month: 0,
    month3: 0,
    month6: 0,
    year: 0,
    yearOld: 0,
  },
  pro: {
    month: 0,
    month3: 0,
    month6: 0,
    year: 0,
    yearOld: 0,
  },
}

export default function PagePrices() {
  const [currentPeriod, setCurrentPeriod] = useState('month')
  const [mobileSelectedTariff, setMobileSelectedTariff] = useState('cashier')

  const [modulesCost, setModulesCostState] = useState(JSON.parse(JSON.stringify(MODULES_COST_INITIAL)))

  const setModulesCost = (tariff, isAdd, price) => {
    const yearOldPrice = price.yearOld || price.year

    const currentTariffPrices = {
      month: modulesCost[tariff].month + (isAdd ? price.month : -price.month),
      month3: modulesCost[tariff].month3 + (isAdd ? price.month3 : -price.month3),
      month6: modulesCost[tariff].month6 + (isAdd ? price.month6 : -price.month6),
      year: modulesCost[tariff].year + (isAdd ? price.year : -price.year),
      yearOld:
            modulesCost[tariff].yearOld + (isAdd ? yearOldPrice : -yearOldPrice),
    }

    setModulesCostState({
      ...modulesCost,
      [tariff]: currentTariffPrices,
    })
  }

  const resetModulesCost = () => {
    setModulesCostState(JSON.parse(JSON.stringify(MODULES_COST_INITIAL)))
  }

  useEffect(() => {
    resetModulesCost()
  }, [currentPeriod])

  // Проверка сделал ли пользователь скролл по вертикали
  const [isScrolled, setIsScrolled] = useState(false)
  const [isScrolledBelowTariffs, setIsScrolledBelowTariffs] = useState(false)
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 200)

    setIsScrolledBelowTariffs(
      featuresEl.current?.getBoundingClientRect().top < 250,
    )
  }

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const featuresEl = useRef(null)

  const localizationContext = useLocalizationContext()

  const faqData = getFaqData(localizationContext.locale)

  const pageTitle = {
    'ru-RU': 'Оптимальные решения для бизнеса',
    'ru-BY': 'Оптимальные решения для бизнеса',
    'ru-KZ': 'Оптимальные решения для бизнеса',
    'kz-KZ': 'Бастауға арналған оңтайлы шешімдер',
  }

  const metaTitle = {
    'ru-RU': 'Тарифы Quick Resto — цены и условия подписки | Quick Resto',
    'ru-KZ': 'Тарифы Quick Resto — цены и условия подписки | Quick Resto Казахстан',
    'kz-KZ': 'Тарифы Quick Resto — цены и условия подписки | Quick Resto Казахстан',
    'ru-BY': 'Тарифы Quick Resto — цены и условия подписки | Quick Resto Беларусь',
  }
  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}price/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}price/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}price/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}price/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Quick Resto - доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги - автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру 8 (800) 500-21-38 или пишите на почту sales@quickresto.ru.',
    'ru-KZ': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +7 (727) 312-31-99 или пишите на почту qrsib@yandex.ru. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +7 (727) 312-31-99 или пишите на почту qrsib@yandex.ru. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Quick Resto — доступная система автоматизации ресторана, кафе, бара, которая экономит ваше время и деньги — автоматизация под ключ, техническая поддержка 24/7, обучение сотрудников и никаких доплат. По всем вопросам звоните по номеру +375-44-7-937-937 или пишите на почту info@quickresto.by. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale],
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
    },
    {
      httpEquiv: 'x-ua-compatible',
      content: 'ie=edge',
    },
  ]
  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  styles.prices,
      )}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
    >
      <div className={styles.prices__backdrop} />

      <div className={styles.prices__headline}>
        <h1 className={styles.prices__title}>
          {pageTitle[localizationContext.locale]}
        </h1>
      </div>

      <div
        className={classNames(
				  styles.prices__tariffsContainer,
				  pageStyles.pageSection,
        )}
      >
        <div
          className={classNames(
					  pageStyles.pageSection,
					  styles.prices__stickyContainer,
          )}
        >
          <Tariffs
            className={classNames(
						  styles.prices__sticky,
						  isScrolled && styles.prices__roundedNone,
						  {
						    [styles.prices__static]: !isScrolledBelowTariffs,
						    [styles.prices__staticScrolled]: isScrolledBelowTariffs,
						  },
            )}
            scrolledBelowTariffs={isScrolled}
            setMobileSelectedTariff={setMobileSelectedTariff}
            setCurrentPeriod={setCurrentPeriod}
            currentPeriod={currentPeriod}
            modulesCost={modulesCost}
          />
        </div>

        <div
          ref={featuresEl}
          className={classNames(
					  pageStyles.pageSection,
					  styles.prices__featuresWrapper,
          )}
        >
          <Features
            mobileSelectedTariff={mobileSelectedTariff}
            currentPeriod={currentPeriod}
            setModulesCost={setModulesCost}
          />
        </div>

        <DetailFeatures
          mobileSelectedTariff={mobileSelectedTariff}
          className={pageStyles.pageSection}
        />
      </div>

      <LoyaltyTariffs className={pageStyles.pageSection} />

      <EnterpriseCta className={pageStyles.pageSection} />

      <Faq data={faqData} className={pageStyles.pageSection} />

      <Services className={pageStyles.pageSection} />

      <CtaForm
        isWhiteBackground
        isFry={false}
        className={pageStyles.pageSection}
      />

      <OurCustomers isPricePage className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
